import React from 'react';
import Image from 'gatsby-image';
import Link from './Link';
import theme from '../styles/theme';
import videoIcon from '../assets/images/icon-video-big@3x.png';
import { ContentfulAsset } from '../graphql-types';

interface OwnProps {
  to: string;
  image?: ContentfulAsset;
  meta?: string;
  title: string;
}

const VideoCard: React.FC<OwnProps> = ({ to, image, meta, title }) => (
  <Link
    css={{
      flexDirection: 'column',
      flex: 1,
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit',
      },
    }}
    to={to}
  >
    <div
      css={{
        marginBottom: 32,
        borderRadius: 20,
        backgroundColor: '#ffffff',
        overflow: 'hidden',
        boxShadow: '0 2px 11px 0 rgba(0, 0, 0, 0.15)',
      }}
    >
      {image ? (
        <div css={{ position: 'relative' }}>
          <Image fluid={{ ...image.fluid, aspectRatio: 16 / 9 }} />
          <img
            alt="video icon"
            src={videoIcon}
            css={{
              marginBottom: 0,
              position: 'absolute',
              top: 'calc(50% - 35px)',
              left: 'calc(50% - 35px)',
              height: 70,
              width: 70,
            }}
          />
        </div>
      ) : (
        <div
          css={{
            width: '100%',
            height: 165,
            backgroundColor: theme.colors.lightBg,
          }}
        />
      )}
      <div css={{ padding: '1rem', paddingTop: 9 }}>
        <span
          css={{
            fontSize: 12,
            lineHeight: 2.5,
            letterSpacing: '1px',
            color: theme.colors.text,
          }}
        >
          {meta}
        </span>
        <h3
          css={{
            marginBottom: 0,
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.45,
            letterSpacing: '-0.62px',
          }}
        >
          {title}
        </h3>
      </div>
    </div>
  </Link>
);

export default VideoCard;
