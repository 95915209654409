import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'emotion-flex';
import { SEO, Button } from '../components';
import { withLayout } from '../components/Layout';
import Video from '../components/Video';
import VideoCard from '../components/VideoCard';
import presets from '../styles/presets';
import theme from '../styles/theme';
import {
  ContentfulVideoPostConnection,
  ContentfulVideoPage,
} from '../graphql-types';

interface Props {
  pageContext: {
    locale: string;
  };
  data: {
    page: ContentfulVideoPage;
    videos: ContentfulVideoPostConnection;
  };
}

const ButtonLink = Button.withComponent(Link);

const VideoPage: React.FC<Props> = ({
  pageContext: { locale },
  data: { page, videos },
}) => {
  return (
    <div
      css={{
        [presets.sm]: {
          marginTop: 26,
        },
      }}
    >
      <SEO
        lang={locale}
        title={page.seoTitle}
        description={page.seoDescription}
      />
      <div css={{ maxWidth: 730, marginLeft: 'auto', marginRight: 'auto' }}>
        <Video videoSrcURL={page.youTubeEmbedUrl} css={{ margin: 0 }} />
      </div>
      <section
        css={{ backgroundColor: '#F4F7F8', paddingTop: 48, paddingBottom: 24 }}
      >
        <Container>
          <Row>
            <Col xs={12} lgOffset={2} lg={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.featuredVideoSection.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section css={{ paddingTop: 12 }}>
        <Container>
          <Row>
            <Col xs={12} lgOffset={2} lg={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.whiteTextBlock.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Container
        fluid
        css={{
          [`${presets.lg}`]: {
            maxWidth: theme.maxWidths.lg,
          },
          [`${presets.xl}`]: {
            maxWidth: theme.maxWidths.xl,
          },
        }}
      >
        <Row>
          <Col xs={12} lgOffset={2} lg={8}>
            <Row
              css={{
                display: 'grid',
                overflowY: 'scroll',
                gridAutoFlow: 'column',
                maskImage:
                  'linear-gradient(to right, transparent, black 20px, black 90%, transparent)',
                [`${presets.lg}`]: {
                  overflowY: 'hidden',
                  display: 'flex',
                  maskImage: 'none',
                  maxWidth: theme.maxWidths.lg,
                },
              }}
            >
              {videos.nodes.map(
                ({ id, slug, title, metaInformation, thumbnail }) => (
                  <Col
                    key={id}
                    xs={12}
                    md={6}
                    css={{
                      minWidth: 320,
                      [`${presets.lg}`]: {
                        minWidth: 'auto',
                      },
                    }}
                  >
                    <VideoCard
                      image={thumbnail}
                      to={`/we_care/${slug}/`}
                      meta={metaInformation}
                      title={title}
                    />
                  </Col>
                )
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <section
        css={{
          paddingTop: 48,
          paddingBottom: 64,
          backgroundColor: theme.colors.lightBg,
        }}
      >
        <Container>
          <Row>
            <Col xs={12} lgOffset={2} lg={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.sendUsAVideoSection.childMarkdownRemark.html,
                }}
              />
              <ButtonLink to="/de/we_care/mitmachen/">Mitmachen</ButtonLink>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={{ marginTop: 32 }}>
        <Container>
          <Row>
            <Col xs={12} lgOffset={2} lg={8}>
              <p css={{ fontSize: '1rem', color: '#505b7d', marginBottom: 0 }}>
                {page.socialBelowSection}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export const videoPageQuery = graphql`
  query videoPageQuery($id: String!, $locale: String!) {
    page: contentfulVideoPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      youTubeEmbedUrl
      featuredVideoSection {
        childMarkdownRemark {
          html
        }
      }
      whiteTextBlock {
        childMarkdownRemark {
          html
        }
      }
      sendUsAVideoSection {
        childMarkdownRemark {
          html
        }
      }
      socialSection {
        childMarkdownRemark {
          html
        }
      }
      socialBelowSection
    }
    videos: allContentfulVideoPost(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        slug
        title
        metaInformation
        thumbnail {
          fluid(maxWidth: 510, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

export default withLayout(VideoPage, { isNewLayout: true });
